import { AttenSystemApi } from '@/api/attendance/attenSystem/AttenSystemApi';

export class AttenSystemService {
    private api = new AttenSystemApi();
    
    //获取考勤制度
    public getDetail(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDetail().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    } 
}