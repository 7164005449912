
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import { AttenSystemService } from "@/services/attendance/attenSystem/AttenSystemService.ts";
export default defineComponent({
  name: "institution",
  components: {
    Header,
  },
  data() {
    return {
      api: new AttenSystemService(),
      content: "",
    };
  },
  mounted() {
    this.api
      .getDetail()
      .then((res: any) => {
        //  let style='<style lang="scss" scoped>'
        // +'.content{ color: #FFFFFF; }'
        // +'.ql-align-center{ text-align: center;color: #0f4c81; }'
        // +'p{color: #0f4c81; font-size:0.4rem; line-height: 0.8rem;  }'
        // +'span,strong{background: #00000000  !important; text-align: center;color: #0f4c81 !important; }'
        // +'</style>';
        this.content = res.content;
        console.log(this.content);
      })
      .catch(() => {});
  },
});
