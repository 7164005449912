import AttendanceBaseService from '../base/AttendanceBaseService';

export class AttenSystemApi extends AttendanceBaseService {
    constructor() {
        super("attenSystem");
    }

    /**
   * 获取考勤制度
   */
    public getDetail(): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, null);
    }
}